// React 16.8.6  |  https://17.reactjs.org/docs/
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import styled from 'styled-components'

import Layout from '../components/Layout'
import API from '../API'

// ===================================================================
// Style
// ===================================================================
const colors = {
	primary: '#191918',
	secondary: '#61615F',
	background: '#F3F3F1',
	backgroundLight: '#FBFBF9',
	accent: '#FF6C64',
	accentGradient: '#FF8E66',
	border: '#BDBDBA',
};

const keyframes = `
   @keyframes moveGradient {
        0%, 100% { background-position: 0% 50%; }
        50% { background-position: 100% 50%; }
    }
    @keyframes pulseGlow {
        0%, 100% {
            box-shadow: 0 0 15px rgba(255, 108, 100, 0.5),
            0 0 20px rgba(255, 142, 102, 0.5),
            0 0 45px rgba(255, 108, 100, 0.25);
        }
        50% {
            box-shadow: 0 0 25px rgba(255, 108, 100, 0.7),
            0 0 30px rgba(255, 142, 102, 0.7),
            0 0 50px rgba(255, 108, 100, 0.4);
        }
    }
    @keyframes shimmer {
        0% { background-position: -200% 0; }
        100% { background-position: 200% 0; }
    }
`;

const KeychainLayout = styled(Layout)`
	${keyframes}
	-webkit-tap-highlight-color: transparent;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	
	
	.Group {
        margin-bottom: 26px;

        > .Name {
            padding: 0 0 14px 10px;
            font-weight: 500;
            color: ${colors.secondary};
            font-size: 14px;
        }
    }
	
	.Keys {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
        gap: 8px;
        color: ${colors.primary};
    }

	.Key {
        /* Use GPU acceleration and reduce repaints */
        transform: translate3d(0, 0, 0);
        will-change: transform;
        transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);

        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        padding: 12px 14px;
        background: ${colors.background};
        border-radius: 16px;
        cursor: pointer;
        overflow: hidden;
        grid-row: span 1;
		
		.Name {
			font-size: 14px;
			font-weight: 600;
			color: ${colors.primary};
			max-width: 100%;
			
			span {
				display: inline-block
			}
		}

		.Status {
			font-size: 11px;
			white-space: pre;
			font-weight: 400;
			color: ${colors.secondary};
		}

		.Icon {
			display: block;
			font-size: 24px;
			padding: 6px;
			background-color: ${colors.backgroundLight};
			border-radius: 50%;
			color: ${colors.border};
			max-width: 36px;
		}

		> div {
			display: flex;
			flex-direction: column;
			gap: 2px;
			max-width: 100%;
		}

		/* States */

		&:active {
			transform: scale(0.95);
			transition: transform 0.1s cubic-bezier(0.4, 0, 0.2, 1);
		}

		&.busy {
			background: linear-gradient(90deg, ${colors.accent} 0%, ${colors.accentGradient} 100%);
			background-size: 200% 100%;
			animation: moveGradient 1s linear .5s infinite, pulseGlow 1.5s ease-in-out infinite;
			z-index: 5;

			/* Use CSS transforms instead of box-shadow for better performance */
			&::after {
				content: '';
				position: absolute;
				inset: -5px;
				background: inherit;
				filter: blur(10px);
				opacity: 0.5;
				z-index: -1;
			}

			.Name, .Status { color: white; }
			.Icon { color: ${colors.accent}; }
		}

		&.isOpen {
			background: linear-gradient(90deg, ${colors.accent} 0%, ${colors.accentGradient} 100%);

			.Name, .Status { color: white; }
			.Icon { color: ${colors.accent}; }
		}

		/* Variants */
		&.size-large {
			grid-row: span 2;
			flex-direction: column;
			align-items: flex-start;
			justify-content: space-between;
		}

		&.size-long {
			grid-column: span 2;
			flex-direction: column;
			align-items: flex-start;
		}
		
		&.skeleton {
            background: linear-gradient(90deg, ${colors.background} 25%, ${colors.backgroundLight} 50%, ${colors.background} 75%);
            background-size: 200% 100%;
            animation: shimmer 1.5s infinite;
            flex-direction: column;
            align-items: center;

            .Icon {
                width: 40px;
                height: 40px;
                background: ${colors.background};
            }

            .Name {
                width: 60%;
                height: 13px;
                background: ${colors.background};
                border-radius: 4px;
            }
        }
	}

	/* QuickAccessBar styles */
	.QuickAccessBar {
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 100;
		padding: 16px 20px;
		padding-bottom: calc(26px + env(safe-area-inset-bottom));
		border-top-left-radius: 38px;
		border-top-right-radius: 38px;
		background: rgba(255, 255, 255, 0.65);
		backdrop-filter: blur(10px) saturate(180%);
		border-top: 1px solid rgba(255, 255, 255, 0.8);
		transform: translate3d(0, ${props => props.hidden ? '100%' : '0'}, 0);
		transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
		will-change: transform;
		touch-action: none;
		box-shadow: 0 -4px 24px rgba(0, 0, 0, 0.08);

		/* Gradient border */
		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			height: 1px;
			background: linear-gradient(90deg,
			rgba(255, 255, 255, 0),
			rgba(255, 255, 255, 0.8),
			rgba(255, 255, 255, 0)
			);
		}

		&.closed {
			transform: translateY(100%);
			.Key {
				transform: scale(0.8);
				opacity: 0.6;
			}
		}
		
		.quick {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			gap: 12px;
			padding: 4px;
			
			.Key {
				flex-direction: column;
				
				.Name {
					font-size: 12px;
				}
			}
		}
		
		@media (min-width: 768px) {
			display: none;
		}
	}


	/* Switch styles */
	.switch {
		position: relative;
		display: inline-block;
		width: 40px;
		height: 20px;

		input {
			opacity: 0;
			width: 0;
			height: 0;

			&:checked + .slider {
				background-color: ${colors.primary};

				&:before {
					transform: translateX(20px);
				}
			}
		}
	}
	.slider {
		position: absolute;
		inset: 0;
		background-color: #E5E5E2;
		transition: 0.3s;
		cursor: pointer;
		border-radius: 34px;

		&:before {
			content: "";
			position: absolute;
			height: 14px;
			width: 14px;
			left: 3px;
			bottom: 3px;
			background-color: #FFFFFD;
			transition: 0.3s;
			border-radius: 50%;
		}
	}

	.shellyApiToggle {
		position: absolute;
		top: 32px;
		right: 20px;
		display: flex;
		align-items: center;
		gap: 6px;
	}

	.shellySwitch {
		font-weight: 500;
		color: ${colors.secondary};
		font-size: 14px;
	}
`


// ===================================================================
// Constants
// ===================================================================
const OPEN_DURATION = 3000;
const SCROLL_THRESHOLD = 50;
const UPDATE_DELAY = 100;
const iconMap = new Map([
	['door', 'door_front'],
	['gate', 'gate'],
	['fence', 'outdoor_garden'],
	['cafe', 'local_cafe'],
	['home', 'other_houses'],
	['truck', 'local_shipping'],
	['club', 'star'],
]);

// ===================================================================
// Utility Functions
// ===================================================================

const hapticFeedback = {
	light: debounce(() => navigator?.vibrate?.(10), 100),
	medium: debounce(() => navigator?.vibrate?.(20), 100),
	heavy: debounce(() => navigator?.vibrate?.([30, 10, 30]), 100),
	success: debounce(() => navigator?.vibrate?.([10, 30, 10]), 100),
	error: debounce(() => navigator?.vibrate?.([50, 20, 50, 20, 50]), 100)
};

function debounce(func, wait) {
	let timeout;
	return (...args) => {
		clearTimeout(timeout);
		timeout = setTimeout(() => func(...args), wait);
	};
}

function getKeyIcon(key, state) {
	if(state === true && key.icon === 'door') return 'door_open';
	return key.momentary && key.icon === 'gate'
		? 'gate'
		: iconMap.get(key.icon) || 'key';
}


// ===================================================================
// Subcomponents
// ===================================================================

const ShellyApiToggle = React.memo(() => {
	const [isChecked, setIsChecked] = useState(() => API.shared.useShellyApi ?? true);
	API.shared.useShellyApi = true;
	const handleToggle = useCallback(() => {
		setIsChecked(prev => {
			const newValue = !prev;
			API.shared.useShellyApi = newValue;
			return newValue;
		});
	}, []);

	return (
		<div className="shellyApiToggle">
			<span className="shellySwitch">Cloud</span>
			<label className="switch">
				<input
					type="checkbox"
					checked={!isChecked}
					onChange={handleToggle}
				/>
				<span className="slider round" />
			</label>
		</div>
	);
}, (prevProps, nextProps) => prevProps === nextProps);

const KCGroup = React.memo(({ group, keychain }) => (
	<div className="Group">
		<div className="Name">{group.name}</div>
		<div className="Keys">
			{group.keys.map((key) => (
				<KCKey key={key.id} theKey={key} keychain={keychain} />
			))}
		</div>
	</div>
));

const QuickAccess = React.memo(({ keys, keychain }) => {
	const validKeys = useMemo(() => {
		if (!keys?.length || !keychain?.data?.keyGroups) return [];

		return keys
			.map(key => {
				const fullKey = keychain.data.keyGroups
					.flatMap(g => g.keys)
					.find(k => k.id === key.resourceId);
				return fullKey ? { ...key, fullKey } : null;
			})
			.filter(Boolean);
	}, [keys, keychain?.data?.keyGroups]);

	if (!validKeys.length) {
		return <div className="QuickAccessBar closed" />;
	}

	return (
		<div className="Keys quick">
			{validKeys.map(({ resourceId, fullKey }) => (
				<KCKey
					key={resourceId}
					theKey={fullKey}
					keychain={keychain}
					quick={true}
				/>
			))}
		</div>
	);
});

const KCKey = React.memo(({ theKey, keychain, quick }) => {
	const [state, setState] = useState({ busy: false, duration: 0 });

	useEffect(() => {
		if(theKey.duration) {
			setTimeout(() => {
				setState({ busy: false, duration: 0 });
			}, theKey.duration * 1000);
		}
	}, []);

	useEffect(() => {
		setState({ busy: theKey.state, duration: theKey.duration });
	}, [theKey]);

	const useKey = useCallback(() => {
		setState(prevState => {
			if (!prevState.busy) {
				keychain.useKey(theKey, () => {
					setTimeout(() => {
						setState({ busy: false, duration: 0 });
					}, OPEN_DURATION);
				});
				return { busy: true, duration: 3 };
			}
			return prevState;
		});
	}, [theKey, keychain]);

	const handleTouchStart = (event) => {
		console.log('Touch coordinates:', event.touches[0].clientX, event.touches[0].clientY);
	};


	const icon = useMemo(() => getKeyIcon(theKey, state.busy), [state.busy, state.duration]);
	const classNames = useMemo(() => {
		const classes = ['Key'];
		if (state.busy && state.duration === 0) classes.push('isOpen');
		if (state.busy && state.duration > 0) classes.push('busy');
		if (quick) classes.push('quick-access');
		if (theKey.size && !quick) classes.push(`size-${theKey.size}`);
		return classes.join(' ');
	}, [state.busy, state.duration, quick, theKey.size]);
	const displayName = useMemo(() =>
			quick && theKey.id === 'ov6b-draugiem' ? 'R • PP • D' : theKey.name
		, [quick, theKey.name]);


	return (
		<div className={classNames} onClick={useKey} onTouchStart={handleTouchStart}>
			<div>
				<span className="material-symbols-outlined Icon">{icon}</span>
			</div>
			<div>
				<div className="Name">
					{displayName.split(' | ').map((part, index, array) => (
					<span key={index}>
								{part}
						{index < array.length - 1 && <span> &nbsp;</span>}
							</span>
				))}</div>
				<div className="Status"></div>
			</div>
		</div>
	);
}, (prevProps, nextProps) => {
	return (
		prevProps.theKey.id === nextProps.theKey.id &&
		prevProps.quick === nextProps.quick &&
		prevProps.theKey.state === nextProps.theKey.state &&
		prevProps.theKey.duration === nextProps.theKey.duration
	);
});


const useKeychain = () => {
	const [data, setData] = useState(null);
	const [quickAccess, setQuickAccess] = useState(() =>
		JSON.parse(localStorage.getItem('quickAccess')) || []
	);
	const wsRef = useRef(null);

	const loadData = useCallback(async () => {
		try {
			const response = await API.shared.get('keychain'); // Fetch the keychain structure
			setData(response);
		} catch (error) {
			console.error('Error loading keychain data:', error);
		}
	}, []);

	const loadRecentlyUsed = useCallback(async () => {
		const response = await API.shared.get('keychain/recently-used');
		const existingQuickAccess = JSON.parse(localStorage.getItem('quickAccess')) || [];
		const existingKeysMap = new Map(existingQuickAccess.map(key => [key.resourceId, key]));

		const updatedQuickAccess = existingQuickAccess.filter(key =>
			response.keys.some(newKey => newKey.resourceId === key.resourceId)
		);

		response.keys.forEach(newKey => {
			if (!existingKeysMap.has(newKey.resourceId)) {
				updatedQuickAccess.push(newKey);
			}
		});

		localStorage.setItem('quickAccess', JSON.stringify(updatedQuickAccess));
		setQuickAccess(updatedQuickAccess);
	}, []);

	const useKey = useCallback(async (key, callback) => {
		try {
			const response = await API.shared.post('keychain/use-key', {
				id: key.id,
				toggleApi: API.shared.useShellyApi
			});

			wsRef.current?.send(JSON.stringify({
				type: 'key-use',
				resourceId: key.id,
				time: new Date().toISOString()
			}));

			callback?.(response?.success);
			setTimeout(loadData, 3000);
		} catch (error) {
			console.error('Error using key:', error);
			callback?.(false);
		}
	}, [loadData]);

	useEffect(() => {
		loadData();
		loadRecentlyUsed();
	}, [loadData]);

	useEffect(() => {
		const connectWebSocket = () => {
			const wsUrl = `wss://app.hex.lv/wss`;

			const ws = new WebSocket(wsUrl);
			wsRef.current = ws;

			ws.onopen = () => {
				console.log('Connected to WebSocket server');
				ws.send(JSON.stringify({ type: 'subscribe' }));
			};

			ws.onmessage = (event) => {
				const message = JSON.parse(event.data);
				console.log('Message received from server:', message);

				switch (message.type) {
					case 'key-update':
						setData((prevData) => {
							if (!prevData) return prevData;

							// Find the relevant key and update its state
							const updatedKeyGroups = prevData.keyGroups.map((group) => ({
								...group,
								keys: group.keys.map((key) =>
									key.id === message.resourceId
										? { ...key, state: message.state, duration: message.time }
										: key
								),
							}));

							return { ...prevData, keyGroups: updatedKeyGroups };
						});
						break;
					case 'error':
						console.error('WebSocket error:', message.error);
						break;
				}
			};

			ws.onclose = () => {
				console.log('WebSocket connection closed, attempting reconnect...');
				setTimeout(connectWebSocket, 5000);
			};

			ws.onerror = (error) => {
				console.error('WebSocket error:', error);
			};
		};

		connectWebSocket();
		return () => wsRef.current?.close();
	}, []);


	return {
		data,
		quickAccess,
		useKey,
		loadData
	};
};
// ===================================================================
// View Component
// ===================================================================
const Keychain = () => {
	const keychain = useKeychain();
	const [quickAccessHidden, setQuickAccessHidden] = useState(false);
	const [lastScrollY, setLastScrollY] = useState(0);


	const scrollTimeout = useRef(null);
	const handleScroll = useCallback(() => {
		if (scrollTimeout.current) {
			clearTimeout(scrollTimeout.current);
		}

		scrollTimeout.current = setTimeout(() => {
			const currentScrollY = window.scrollY;
			const windowHeight = window.innerHeight;
			const documentHeight = document.documentElement.scrollHeight;
			const isAtBottom = (windowHeight + currentScrollY) >= documentHeight - 10;

			if ((currentScrollY > lastScrollY && currentScrollY > 10) || isAtBottom) {
				setQuickAccessHidden(true);
			} else if (currentScrollY < lastScrollY) {
				setQuickAccessHidden(false);
			}
			setLastScrollY(currentScrollY);
		}, 50);
	}, [lastScrollY, quickAccessHidden]);

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
		return () => window.removeEventListener('scroll', handleScroll);
	}, [handleScroll]);

	useEffect(() => {
		const handleVisibilityChange = () => {
			if (document.visibilityState === 'visible') {
				console.log('App is back in focus! Fetching key status...');
				keychain.loadData();
			}
		};

		document.addEventListener('visibilitychange', handleVisibilityChange);

		return () => {
			document.removeEventListener('visibilitychange', handleVisibilityChange);
		};
	}, [keychain]);

	if (!keychain.data) {
		return <Layout isLoading />;
	}

	return (
		<KeychainLayout title="Keychain">
			<ShellyApiToggle value={API.shared.useShellyApi} />
			{keychain.data.keyGroups.map(group => (
				<KCGroup key={group.name} group={group} keychain={keychain} />
			))}
			{API.shared.hasPermission('EXPERIMENTAL_FEATURES') && (
				<div className={`QuickAccessBar ${quickAccessHidden ? "closed" : "peeking"}`}>
					<QuickAccess keys={keychain.quickAccess} keychain={keychain} />
				</div>
			)}
		</KeychainLayout>
	);
};

export default Keychain;



