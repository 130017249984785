
// ===================================================================

import React from 'react'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'

import SideMenu from './SideMenu'
import Modal, { ModalContainer } from './Modal'

// ===================================================================

const LayoutStyle = styled.div`
	min-height: 100%;
	
	.SideMenu {
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		width: 300px;
		overflow: auto;
		z-index: 1000;

		transition: transform 0.3s ease-in-out;
	}
	.ContentBlackout,
	.SideMenuButton {
		display: none;
	}
	.MainContent {
		margin-left: 300px;
		overflow: auto;
		display: flex;
		flex-direction: column;
		min-height: 100vh;
		
		& > .Header {
			padding: 20px;
			display: flex;
			align-items: center;
			
			h1 {
				font-size: 26px;
				span {
					font-weight: 400;
				}
			}
		}
		& > .Loading {
			margin: auto auto;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		& > .Content {
			padding: 20px;
		}
		
	}
	&.layoutCenter {
		.MainContent > .Content {
			display: flex;
			justify-content: center;
			flex-wrap: wrap;
		}
	}
	/* MOBILE Layout */
	@media (max-width: 700px) {
		.SideMenu {
			transform: translateX(-100%);
		}
		.SideMenuButton {
			height: 40px;
			display: block;
			padding: 8px;
			background-color: #F3F3F1;
			border-radius: 50%;
			margin-right: 10px;
		}
		.ContentBlackout {
			display: none;
			position: fixed;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background: rgba(25, 25, 24, 0.6);
			z-index: 900;
		}
		.MainContent {
			margin-left: 0;

			& > .Header {
				padding: 20px 10px 10px 20px;

				h1 {
					font-size: 18px;
				}
			}
		}
		&.sideMenuOpen {
			.SideMenu {
				transform: translateX(0);
			}
			.SideMenuButton {
			}
			.ContentBlackout {
				display: block;
			}
		}
	}
`

// ===================================================================

class LayoutClass extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			sideMenuOpen: false
		}
	}
	componentDidUpdate(prevProps) {
		if(this.props.location !== prevProps.location) {
			this.hideSideMenu()
		}
	}
	hideSideMenu() {
		this.setState({ sideMenuOpen: false })
	}
	toggleSideMenu() {
		this.setState({ sideMenuOpen: !this.state.sideMenuOpen })
	}
	render() {
		const classNames = [ this.props.className ]
		if(this.state.sideMenuOpen) {
			classNames.push('sideMenuOpen')
		}
		if(this.props.center) {
			classNames.push('layoutCenter')
		}
		return (
			<LayoutStyle className={ classNames.join(' ') }>
				<div className="SideMenu">
					<SideMenu/>
				</div>
				<div className="ContentBlackout" onClick={ e => this.hideSideMenu(e) }/>
				<div className="MainContent">
					{ this.props.title  &&
						<div className="Header">
							<div className="SideMenuButton" onClick={e => this.toggleSideMenu(e)}>
								<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960"
									 width="24px" fill="#191918">
									<path d="M120-240v-80h720v80H120Zm0-200v-80h720v80H120Zm0-200v-80h720v80H120Z"/>
								</svg>
							</div>
							<h1><span>HEX </span>{this.props.title}</h1>
						</div>
					}
					{this.props.isLoading && <div className="Loading">Loading...</div>}
					{!this.props.isLoading && <div className="Content">{this.props.children }</div> }
				</div>
				<ModalContainer/>
			</LayoutStyle>
		)
	}
}

export default function Layout(props) {
	return <LayoutClass { ...props }/>
}

// ===================================================================
