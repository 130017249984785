
// ===================================================================

import React from 'react'
import ReactDOM from 'react-dom'
import { createGlobalStyle, ThemeProvider } from 'styled-components'

import App from './components/App'

// ===================================================================

const GlobalStyle = createGlobalStyle`
	@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');
	$primary-color: green;
	.material-symbols-outlined {
		display: inline-block;
		text-transform: none;
		letter-spacing: normal;
		word-wrap: normal;
		white-space: nowrap;
		direction: ltr;
		font-variation-settings:
			'FILL' 1,
			'wght' 400,
			'GRAD' 0,
			'opsz' 24
	}
	link[rel="manifest"] {
		--pwacompat-splash-font: 24px 'Plus Jakarta Sans';
	}
	
	:root {
		--background-color: #FFFFFD;
	}

	html {
		font-family: 'Plus Jakarta Sans', sans-serif;
		background: #FFFFFD;
		position: relative;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		
	}
	html, body, #root {
		height: 100%;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
	}
	a {
		text-decoration: none;
	}
	.Header {
		padding: 20px 10px;
	}
	.Content {
		padding: 10px;
	}
	button,
	.ButtonWrap a {
		border: 1px solid #0151ca !important;
		background: #1373ec;
		color: white;
		margin: 2px;
		padding: 5px 10px;
		font-weight: bold;
		font-size: 15px;
		border-radius: 4px;
		margin-bottom: 10px;
	}
	.ButtonWrap a {
		display: inline-block;
	}
`

const theme = {
	something: 'red'
}

// ===================================================================

ReactDOM.render(
	<React.Fragment>
		<GlobalStyle/>
		<ThemeProvider theme={ theme }>
			<App/>
		</ThemeProvider>
	</React.Fragment>
, document.getElementById("root"))

// ===================================================================
