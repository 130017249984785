
// ===================================================================

import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

import API from '../API'
import HexSvg from '../images/orange-key.png'

import views from '../views'

// ===================================================================

const SideMenuStyle = styled.div`
	min-height: 100%;
	color: #191918;
	background: linear-gradient(135deg, #F3F3F1, #FBFBF9);
	box-shadow: 4px 0 10px rgba(0, 0, 0, 0.1);
	padding: 20px;
	
	& > .User {
		padding: 30px 15px 40px;
		text-align: center;
		
		& > .Image {
			margin-bottom: 14px;
			img {
				height: 62px;
				width: 62px;
				transition: opacity 0.3s ease-in-out;
				
				:hover {
					opacity: 0.4;
				}
			}
		}
		& > .Name {
			justify-content: space-between;
			align-items: center;
			font-weight: 500;
			font-size: 18px;

			& > .Value {
				flex-grow: 1;
				font-weight: 600;
				font-size: 18px;
				margin-bottom: 4px;
			}
		}
		& > .Company {
			& > .Entry {
				font-size: 14px;
				color: #61615F;
			}
		}
		& > .Options {
			color: #949491;
			cursor: pointer;
			margin-top: 10px;
			span {
				font-size: 24px;
			}
			:hover {
				color: #61615F;
			}
		}
	}
	
	& > a { /* NavLink */
		color: #61615F;
		padding: 10px 15px;
		font-weight: 400;
		font-size: 15px;
		display: flex;
		align-items:center;
		
		:hover {
			color: #191918;
		}

		span {
			margin-right: 6px;
		}
		&.active {
			color: #191918;
			background: #E5E5E2;
			border-radius: 10px;
			margin-left: 8px;
			padding-left: 7px;
			margin-right: 8px;
			padding-right: 7px;
			font-weight: 700;
		}
	}
`

// ===================================================================

export default class SideMenu extends React.Component {
	reservaLogin(e) {
		e.preventDefault()
		API.shared.get('auth/get-reserva-login').then(data => {
			if(data && data.redirectUrl) {
				location.href = data.redirectUrl
			}
		})
		return false
	}
	render() {
		return (
			<SideMenuStyle>
				<div className="User">
					<div className="Image">
						<img src={HexSvg}/>
					</div>
					<div className="Name">
						<div className="Value">{API.shared.user.name}</div>
					</div>
					<div className="Company">
						{API.shared.user.companies.map(company =>
							(company.active &&
								<div className="Entry" key={company.companyId}>
									{API.shared.companyName(company.companyId)}
								</div>
							)
						)}
					</div>
					<div className="Options">
						<span className="material-symbols-outlined" onClick={e => API.shared.logout()}>logout</span>
					</div>
				</div>
				{views.map(view => {
					if (!view.sideMenu) {
						return null
					}
					if (view.requiresPermission && !API.shared.hasPermission(view.requiresPermission)) {
						return null
					}
					return <MenuLink key={view.path} to={view.path} title={view.sideMenu.title}
									 icon={view.sideMenu.icon}/>
				})}
				<a href="https://hex.lv/rekviziti/" className="">
					<span className="material-symbols-outlined">info</span>
					Company Details
				</a>
				<a href="https://dg.caterevo.com/" className="" style={{paddingTop: '30px'}}>
					<span className="material-symbols-outlined">open_in_new</span>
					Caterevo
				</a>
				<a href="#" className="" onClick={e => this.reservaLogin(e)}>
					<span className="material-symbols-outlined">open_in_new</span>
					Reserva
				</a>
				<a href="https://1work.com/dashboard" className="">
					<span className="material-symbols-outlined">open_in_new</span>
					1WORK
				</a>
				<a href="https://draugiemgroup.freshdesk.com/" className="">
					<span className="material-symbols-outlined">open_in_new</span>
					Freshdesk
				</a>
			</SideMenuStyle>
		)
	}
}

function MenuLink(props) {
	return (
		<NavLink {...props}>
			<span className="material-symbols-outlined">{props.icon}</span>
			{props.title}
		</NavLink>
	)
}

// ===================================================================
